import Script from 'next/script';
import useConsentDataService from '@/store/consent/consent';
import { isVendorConsented } from '@/store/consent/selectors';

declare global {
  interface Window {
    Chartbeat: any;
    _sf_async_config: any;
  }
}
interface ChartbeatProps {
  chartbeatUid: string;
  domain: string;
  sections: string;
  authors: string;
  title: string;
  chartbeatSrc: string;
  path: string;
}

const Chartbeat: React.FC<ChartbeatProps> = ({
  chartbeatUid,
  chartbeatSrc,
  domain,
  sections = '',
  authors = '',
  title = '',
  path = ' ',
}) => {
  const consentStore = useConsentDataService();
  const isConsentedChartbeat = isVendorConsented('chartbeat')(consentStore);

  return (
    <>
      {isConsentedChartbeat && (
        <Script async key="chartbeat-script" id="chartbeat-script" data-testid="chartbeat-script">
          {`(function() {
          var _sf_async_config = window._sf_async_config = (window._sf_async_config || {});
          _sf_async_config.uid = '${chartbeatUid}';
          _sf_async_config.domain = '${domain}';
          _sf_async_config.flickerControl = false;
          _sf_async_config.useCanonical = true;
          _sf_async_config.useCanonicalDomain = true;
          _sf_async_config.sections = '${sections}';
          _sf_async_config.authors = '${authors}';
          _sf_async_config.title = '${title}';
          _sf_async_config.path = '${path}';
          function loadChartbeat() {
            var e = document.createElement('script');
            var n = document.getElementsByTagName('script')[0];
            e.type = 'text/javascript';
            e.async = true;
            e.src = '${chartbeatSrc}';
            n.parentNode.insertBefore(e, n);
          }
          loadChartbeat();
        })();`}
        </Script>
      )}
    </>
  );
};
export default Chartbeat;
