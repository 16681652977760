import styles from './styles/Copyright.module.scss';

interface CopyrightProps {
  year: number;
  publicationObj?: any;
}

const Copyright: React.FC<CopyrightProps> = ({ year, publicationObj }) => (
  <h2 className={styles.copyright}>{`© ${year} ${publicationObj?.[0]?.publication || 'a Reach plc subsidiary'}`}</h2>
);

export default Copyright;
