import { create } from 'zustand';
import { BreakingNewsState } from '@/ts/interfaces/breakingNews';

const useBreakingNews = create<BreakingNewsState>((set, get) => ({
  visible: false,
  title: undefined,
  url: undefined,
  timerRef: undefined,
  setTimerRef: (timerRef) => set(() => ({ timerRef })),
  clearTimeout: () => {
    const { timerRef } = get();
    if (timerRef) clearTimeout(timerRef);
  },
  showBreakingNews: (breakingNewsJson) => set(() => ({ ...breakingNewsJson, visible: true })),
  hideBreakingNews: () => set(() => ({ visible: false })),
}));

export default useBreakingNews;
