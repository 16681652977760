'use client';

import { useRouter } from 'next/navigation';
import Script from 'next/script';
import React, { useEffect } from 'react';
import { ConsentForm, ConsentItem, LoginRadiusEnv, LoginToken, ParsedConsent, ServiceToken, UserInterface } from '@/ts';
import { useLoginStoreContext } from '@/contexts/login/loginProvider';
// Style overrides for Auth UI
import './styles/AuthModal.scss';
import { tokenService } from '@/store/login/loginStoreUtilities';
import getDirFileName from '@/helpers/utils/dirPath';
import { getRegistrationConsent, readProfileByToken, submitConsentByConsentToken, validateToken } from './ApiCalls';

const succesfulLogin = 'auth-ui.authentication';
const triggerLogout = 'auth-ui.logout';

const simulateClick = (elem: any) => {
  const evt = new MouseEvent('click', {
    bubbles: false,
    cancelable: false,
    view: window,
  });
  // const canceled = !elem.dispatchEvent(evt);
  elem.dispatchEvent(evt);
};

declare global {
  interface WindowEventMap {
    'auth-ui.authentication': CustomEvent;
    'auth-ui.logout': CustomEvent;
  }
}

type LoginContainerProps = {
  id: string;
  titleText: string;
};

const Login: React.FC<LoginContainerProps> = ({ id, titleText }) => (
  <div id={id} aria-label={titleText}>
    &nbsp;
  </div>
);

type AuthModalProps = {
  loginRadiusEnv?: LoginRadiusEnv;
  oneTapEnabled?: boolean;
  oneTapClientId?: string;
  publicationId?: string;
  publicationName?: string;
};

const tokenFactory = (accessToken: string, refreshToken: string = '', expiresIn: string = ''): ServiceToken => ({
  accessToken,
  refreshToken,
  expiresIn,
});

export const AuthModal: React.FC<AuthModalProps> = ({
  loginRadiusEnv,
  oneTapEnabled = false,
  oneTapClientId = '628606264312-3iuoscnik8s60s9l89gphhu14d84rrbr.apps.googleusercontent.com',
  publicationId,
  publicationName,
}) => {
  const router = useRouter();
  const id = 'auth-ui-modal';
  const { apiKey, lrDomain, authUiEnv } = loginRadiusEnv || {
    authUiEnv: 'bertha', // BERTHA
    apiKey: '170a7fc5-1449-4da6-931c-3e34e323dc3f', // BERTHA
    lrDomain: 'https://dev-auth.reachplc.com', // BERTHA
  };

  const { actions } = useLoginStoreContext((store: any) => ({
    state: store.state.client,
    actions: store.actions,
  }));

  const cleanup = () => {
    const cleanupUrl = new URL(window.location.href);
    cleanupUrl.searchParams.delete('lrPosition');
    cleanupUrl.hash = '';
    router.replace(cleanupUrl.href, undefined);
    simulateClick(document.querySelector('.modal>.modal__backdrop'));
  };

  useEffect(() => {
    const authUiLoadedHandler = () => {
      // Listen for auth-ui authenticated event and update initials
      window.addEventListener(succesfulLogin, (event: CustomEvent<'auth-ui.authentication'>) => {
        const userData = (event.detail || {}) as { user?: UserInterface };
        if (userData && userData.user) {
          actions.login(userData.user);
        }
      });

      // Listen for a logout event and update initials to icon, clear local storage
      window.addEventListener(triggerLogout, () => {
        actions.logout();
      });

      if (oneTapEnabled) {
        window.dispatchEvent(
          new CustomEvent('auth-ui.allowOneTap', {
            detail: { origin: 'navigator', allow: true },
          })
        );
      }

      // const baseUrl = 'https://api.loginradius.com/identity/v2';
      // const configBaseUrl = "https://config.lrcontent.com/ciam";
      // const authApi = {
      //   bertha: {
      //     url: "https://d2054971f8.execute-api.eu-west-1.amazonaws.com/bertha",
      //   },
      //   stable: {
      //     url: "https://zrs0iux2f6.execute-api.eu-west-1.amazonaws.com/stable",
      //   },
      //   prod: {
      //     url: "https://5yep17ytxf.execute-api.eu-west-1.amazonaws.com/prod",
      //   },
      // };

      // const controller = new AbortController(); // Create an AbortController instance
      // const { signal } = controller;
      const recursiveReadProfileByToken = async (
        // apiKey: string,
        token: string,
        welcomeEmailTemplate?: string
      ) => {
        let response;
        let validToken: LoginToken;
        try {
          response = await readProfileByToken(apiKey, token, welcomeEmailTemplate);
          validToken = await validateToken(apiKey, token);
        } catch (error: Error | any) {
          if (error.code === 1226) {
            tokenService.set(tokenFactory(token));

            const registrationConsents = await getRegistrationConsent(apiKey);
            const mappedConsentList = registrationConsents.map((consentItem: ConsentItem) => {
              const consentTypeForm = consentItem.ConsentForm.find(
                (consentForm: ConsentForm) => consentForm.Type === 'Consent'
              );

              if (!consentTypeForm) {
                return null;
              }

              const consentData = consentTypeForm.Consents.shift();
              if (!consentData) {
                return null;
              }

              return {
                events: consentItem.Events.map((event) => event.Name),
                consentId: consentData.ConsentId,
                title: consentData.Title,
                description: consentData.Description,
                isRequired: consentData.IsRequired,
              };
            });

            const registrationConsent = mappedConsentList.find(
              (consentConfig: ParsedConsent) =>
                consentConfig.events.some(
                  // TODO: where's Data coming from?
                  // (event) => event === registrationConsents[0].Data.Events[0].Name
                  (event) => event === 'Register'
                ) // ideally 'Register'
            );

            // const consentToken = data.Data.ConsentToken;
            const { consentToken } = error;
            const { consentId } = registrationConsent;
            const accepted = true;

            const submittedRegConsent = await submitConsentByConsentToken(apiKey, consentToken, consentId, accepted);
            return submittedRegConsent;
            // recursively call this fn to get the profile again after consent
            // response = await readProfileByToken(
            //   apiKey,
            //   token,
            //   welcomeEmailTemplate
            // );
          }
          // error handling logic similar to the getApiError function in ApiClient.tsx
          throw error;
        }

        return {
          Profile: response,
          ...validToken,
        };
      };

      const exchangeToken = async (token: string) => {
        try {
          const profile = await recursiveReadProfileByToken(token, `welcome-${publicationId}`);
          actions.login(profile.Profile, tokenFactory(profile.access_token, profile.refresh_token, profile.expires_in));
        } catch (error) {
          const fileDirectory = getDirFileName(import.meta.url, exchangeToken.name);
          if (error instanceof Error) {
            if (error.name !== 'AbortError') {
              console.error(`${fileDirectory} Error exchanging google token:`, error);
            }
          } else {
            console.error(`${fileDirectory} An unknown error occurred:`, error);
          }
        } finally {
          cleanup();
        }
      };

      const actualReg = async (event: any) => {
        // hash change handler
        const { hash } = window.location;
        const hashPattern = /^#lr-token=([a-z0-9-]+)$/;
        const match = hashPattern.exec(hash);
        if (match !== null) {
          event.preventDefault();
          event.stopPropagation();
          const token = match[1];
          await exchangeToken(token);
        }
      };
      window.addEventListener('hashchange', actualReg, true);
    };

    window.addEventListener('auth-ui.ready', authUiLoadedHandler);

    // const storageChangeHandler = (event: any) => {
    //   console.log("storage", event);
    //   if (
    //     event.storageArea === localStorage &&
    //     event.key === "auth-user" &&
    //     event.newValue
    //   ) {
    //     actions.setLoggedInStatus(true, JSON.parse(event.newValue));
    //     console.log("storage event", event);
    //   }
    // };
    // window.addEventListener("storage", storageChangeHandler, false);

    // return () => {
    // clear other events recursively
    //   document.removeEventListener('auth-ui.ready', authUiLoadedHandler);
    // };
  }, []);

  const trackingCallback = () => {
    console.debug('AUTH-UI DEBUG++++');
  };

  return (
    <>
      <Script
        strategy="lazyOnload"
        src={`https://s2-prod.${
          publicationId === 'men' ? 'manchestereveningnews' : publicationId
        }.co.uk/@trinitymirrordigital/auth-ui/auth-ui.min.js?v=a1617ceebf2e1b0b2eca66ecfc4e4902`}
        onLoad={() => {
          // @ts-ignore: Auth Ui should be made available on the client
          AuthUI.init({
            initialRoute: '/',
            rootContainerId: id,
            track: trackingCallback,
            origin: null,
            publicationTheme: publicationId,
            newsletter: 'false',
            // newsletterDetails: ['ReachTestingDevRE1', 'Dev+Title+-+Dev+List+01', '000', '000'],
            publicationID: publicationId,
            publicationId,
            publicationName,

            googleOneTap: oneTapEnabled,
            googleOneTapClientID: oneTapClientId,
            googleOneTapRoutes: ['*'],
            googleOneTapScrollPercentage: '0',
            oneTapNewsletter: false,
            enableMagicLink: false,
            enableFacebookLogin: false,
            enableInternalRecaptchaValidation: false,
            // reCaptchaKey: '<KEY>',
            authUiEnv,
            apiKey,
            lrDomain,
            accountHubEnabled: false,
            accountHubVersion: 'ec8cae2c7650cddf58f4ff8f1eabd46ef4a9ccf9',
            marketingOptInEnabled: false,
            socialSignupDisabled: false,
            cssVariables: JSON.parse(
              `{
                "regwall-logo": "url(\\"https://d2cy9dju74ztm4.cloudfront.net/auth-ui/${publicationId}/app_square_logo.png\\")",
                "regwall-edging": "linear-gradient(to bottom, var(--color-sem-surface-neutral-default),  var(--color-sem-surface-neutral-default) 48%, rgba(0, 0, 0, 0) 62%) 1 5%",
                "primary-color": "var(--color-sem-surface-primary-default)",
                "secondary-color": "#fdf1f2",
                "logo":"url(\\"/@trinitymirrordigital/chameleon-branding/publications/${publicationId}/img/logo-${publicationId}.png\\")",
                "regwall": {
                  "edging": "var(--color-sem-surface-neutral-default)",
                  "title-color": "var(--color-sem-text-primary-default)",
                  "title-size": "1.25rem",
                  "title-weight": "700",
                  "border-width": "4px 0",
                  "border-color": "var(--color-sem-surface-primary-default)",
                  "box-shadow": "0 0 5px 0 var(--color-sem-surface-primary-default)",
                  "background-image": "url(\\"https://d2cy9dju74ztm4.cloudfront.net/auth-ui/${publicationId}/LFC_RegWall_Backdrop_Desktop.svg\\")",
                  "background-color": "#eeeeee"
                }
              }`
            ),
          });
        }}
      ></Script>
      <Login id={id} titleText="Log in to your account" />
    </>
  );
};

export default AuthModal;
