'use client';

import { useRef } from 'react';
import { CookieStateData } from '@/ts/';
import useCookie from '../../store/cookie/cookie';

function CookieStoreInitializer({ cookieData }: { cookieData: CookieStateData }) {
  const initialized = useRef(false);

  if (!initialized.current) {
    useCookie.getState().actions.addCookieDataToServerStore(cookieData);
  }

  return null;
}

export default CookieStoreInitializer;
