import { useEffect, useState } from 'react';
import { useAnalyticStore } from '@/store/analytics/analytics-provider';
import styles from './style/Burger.module.scss';

const CloseIcon = (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 1L11 11M1 11L11 1"
      stroke="var(--color-com-header-primary-nav-hamburger-icon)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export interface BurgerProps {
  isOpen?: boolean;
  onClick?: () => void | undefined;
}

const Burger: React.FC<BurgerProps> = ({ isOpen = false, onClick = undefined }) => {
  const [isCloseBtn, setIsCloseBtn] = useState(Boolean(isOpen));

  const {
    actions: { clickEvent },
    state: {
      client: { analytics },
    },
  } = useAnalyticStore((store) => store);

  useEffect(() => {
    setIsCloseBtn(isOpen);
  }, [isOpen]);

  const toggle = () => {
    setIsCloseBtn((prevState) => !prevState);
  };

  const clickHandler = () => {
    if (onClick) {
      onClick();
    } else {
      toggle();
    }

    const event = isCloseBtn ? 'BURGER_CLOSE' : 'BURGER_OPEN';

    const componentTitle = isCloseBtn ? 'Close' : 'Open';

    clickEvent(`HEADER_NAV:${event}`, { componentTitle, linkURL: window.location.href, ...analytics });
  };

  return (
    <button
      className={`${styles['burger-wrapper']} .tab-focus-internal`}
      onClick={clickHandler}
      tabIndex={0}
      role="button"
      aria-label="Hamburger menu"
      aria-expanded={isCloseBtn}
      aria-controls="mobile-nav"
      aria-haspopup="menu"
    >
      <div
        className={isCloseBtn ? styles['burger-close-btn'] : styles['burger-menu']}
        data-testid={isCloseBtn ? 'close-burger' : 'burger'}
      >
        {isCloseBtn && CloseIcon}
      </div>
    </button>
  );
};

Burger.displayName = 'Burger';

export default Burger;
