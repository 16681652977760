import TextLink from '@/components/atoms/TextLink/TextLink';
import Icon from '@/components/atoms/Icon/Icon';
import { TerritorySwitcherProps } from '@/ts/interfaces/territorySwitcher';
import classNames from '@/helpers/utils/classNames';
import styles from './styles/TerritorySwitcher.module.scss';

const TerritorySwitcher: React.FC<TerritorySwitcherProps> = ({ territories, layout = 'row' }) => (
  <div className={classNames(styles, 'container', `${layout}`)} data-testid="territory-switcher">
    <span className={styles.title}>Check out our editions:</span>
    <ul className={styles.list}>
      {territories.map((territory, index) => (
        <li key={index} className={styles.territory}>
          <TextLink href={territory.url} target="_self" disabled={territory.active}>
            <span className={styles.label}>{territory.label}</span>
            <Icon name={territory.label} />
          </TextLink>
        </li>
      ))}
    </ul>
  </div>
);

export default TerritorySwitcher;
