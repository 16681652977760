'use client';

import { ReactElement, Children, isValidElement, useState } from 'react';
import styles from './styles/FloatingFooter.module.scss';

interface ChildComponentProps {
  setPlaceholderHeight: (name: string, elemHeight: number) => void;
}

interface FloatingFooterProps {
  children: ReactElement<ChildComponentProps>[];
}

const usePlaceholderHeight = () => {
  const [placeholderHeight, setTheHeight] = useState(0);
  const elemHeights: Record<string, number> = {};

  const setPlaceholderHeight = (name: string, elemHeight: number) => {
    // Dedupe by checking if the height of the child already pushed
    if (!(name in elemHeights)) {
      elemHeights[name] = elemHeight;
      setTheHeight(Object.values(elemHeights).reduce((accumulator, currentHeight) => accumulator + currentHeight));
    }
  };
  return [placeholderHeight, setPlaceholderHeight] as const;
};

const FloatingFooter: React.FC<FloatingFooterProps> = ({ children }) => {
  const [placeholderHeight, setPlaceholderHeight] = usePlaceholderHeight();

  return (
    <>
      <div id="floating-footer" className={styles['floating-footer']}>
        {Children.map(children, (child) => {
          // If child is a valid element, we can set attributes
          // Add the setPlaceholderHeight func from our custom hook
          if (isValidElement(child)) {
            return {
              ...child,
              props: {
                ...child.props,
                setPlaceholderHeight,
              },
            };
          }
          // if element is not valid just return child
          return child;
        })}
      </div>

      <div
        className={`${styles.scroll} ${placeholderHeight && styles.placeholder}`}
        style={{ height: placeholderHeight ? `${placeholderHeight}px` : '' }}
      />
    </>
  );
};
export default FloatingFooter;
