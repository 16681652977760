import React from 'react';

interface ArrowIconProps {
  color?: string;
  isUp?: boolean;
}

const ArrowIcon: React.FC<ArrowIconProps> = ({ color = 'currentColor', isUp = false }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: `scaleY(${isUp ? '-1' : '1'})` }}
    data-testid="arrow-head-icon"
  >
    <g clipPath="url(#clip0_7405_3605)">
      <path d="M7 10L12 15L17 10" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_7405_3605">
        <rect width="24" height="24" />
      </clipPath>
    </defs>
  </svg>
);

export default ArrowIcon;
