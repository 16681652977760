import { SvgProps } from '@/ts/interfaces/svg';

const UnregisteredIcon: React.FC<SvgProps> = ({ color }) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.16"
      d="M24.7499 24.7499H15.2499C12.3813 24.7499 9.95815 26.6571 9.1792 29.2726C11.7927 32.3196 15.6709 34.25 19.9999 34.25C24.329 34.25 28.2072 32.3196 30.8207 29.2726C30.0417 26.6571 27.6185 24.7499 24.7499 24.7499Z"
      fill="white"
    />
    <path
      d="M34.25 20C34.25 22.9401 33.3596 25.6723 31.8337 27.9415C29.2751 31.7465 24.9299 34.25 20 34.25C15.0701 34.25 10.7249 31.7465 8.16631 27.9415C6.64042 25.6723 5.75 22.9401 5.75 20C5.75 12.1299 12.1299 5.75 20 5.75C27.8701 5.75 34.25 12.1299 34.25 20Z"
      stroke={color}
      strokeWidth="3.16667"
    />
    <path
      d="M23.1667 15.25C23.1667 16.9989 21.7489 18.4167 20 18.4167C18.2511 18.4167 16.8334 16.9989 16.8334 15.25C16.8334 13.5011 18.2511 12.0833 20 12.0833C21.7489 12.0833 23.1667 13.5011 23.1667 15.25Z"
      stroke={color}
      strokeWidth="3.16667"
    />
    <path
      d="M24.7499 24.7499H15.2499C12.3813 24.7499 9.95815 26.6571 9.1792 29.2726C11.7927 32.3196 15.6709 34.25 19.9999 34.25C24.329 34.25 28.2072 32.3196 30.8207 29.2726C30.0417 26.6571 27.6185 24.7499 24.7499 24.7499Z"
      stroke={color}
      strokeWidth="3.16667"
    />
  </svg>
);
export default UnregisteredIcon;
