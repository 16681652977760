'use client';

import { isVendorConsented } from '@/store/consent/selectors';
import useConsentData from '@/store/consent/consent';
import ScriptLoader from '../ScriptLoader/ScriptLoader';

const TWITTER_PIXEL_SCRIPT = `
!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
},s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
twq('config','odjhi');`;

const TwitterPixel: React.FC = () => {
  const consentStore = useConsentData();
  const consented = isVendorConsented('twitter')(consentStore);

  return consented ? (
    <>
      <ScriptLoader testId="twitter-pixel-script" id="twitter-pixel">
        {TWITTER_PIXEL_SCRIPT}
      </ScriptLoader>
    </>
  ) : null;
};
export default TwitterPixel;
