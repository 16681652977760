'use client';

import { useEffect, useState } from 'react';
import gtmPush from '@/helpers/analytics/dataLayer';
import trackBlocksTraversal from './helpers/trackBlocksTraversal';

const ScrollTracker = () => {
  const [onePercentTriggered, setOnePercentTriggered] = useState(false);

  useEffect(() => {
    const handleScrollStart = () => {
      if (!onePercentTriggered) {
        gtmPush({
          event: 'articleScrollDepth_1%',
        });

        setOnePercentTriggered(true);
        window.removeEventListener('wheel', handleScrollStart);

        trackBlocksTraversal();
      }
    };

    const MAX_ATTEMPTS = 3;
    let attempts = 0;

    const checkBodyAndAddListener = () => {
      const body = document.getElementById('article-body');
      if (body) {
        window.addEventListener('wheel', handleScrollStart, { once: true });
      } else if (attempts < MAX_ATTEMPTS) {
        attempts += 1;
        setTimeout(checkBodyAndAddListener, 100);
      } else {
        console.warn('Max attempts reached. Body not found.');
      }
    };

    checkBodyAndAddListener();

    return () => {
      window.removeEventListener('wheel', handleScrollStart);
    };
  }, [onePercentTriggered]);

  return null;
};

export default ScrollTracker;
