'use client';

import { useEffect } from 'react';
import useBreakingNewsStore from '@/store/breakingNews/breakingNews';
import { BreakingNewsJSON, BreakingNewsProps, PollingFunctionOpts } from '@/ts/interfaces/breakingNews';
import { EXCLUDED_SUBTYPES, LOG_PREFIX, POLLING_PLAN, USE_LOCAL_STORAGE } from '@/constants/breakingNews';
import log from '@/helpers/utils/log';
import { useAnalyticStore } from '@/store/analytics/analytics-provider';
import ArrowRight from '@/components/atoms/Icons/ArrowRight';
import Close from '@/components/atoms/Icons/Close';
import Blinker from '@/components/atoms/Blinker/Blinker';
import usePage from '@/store/page/page';
import { getSubType } from '@/store/page/selectors';
import styles from './BreakingNews.module.scss';

export const fetchBreakingNews = (url: string): Promise<BreakingNewsJSON> =>
  fetch(url, { method: 'GET' })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`Failed to fetch article data: ${response.statusText}`);
      }
      return response.json();
    })
    .catch((error) => {
      log().error({ message: `${LOG_PREFIX} Fetch Error`, error });
      return {};
    });

const checkForBreakingNews =
  ({ endpoint, pollingPlan, state }: PollingFunctionOpts) =>
  async () => {
    const json = await fetchBreakingNews(endpoint);
    const validBreakingNewsJson = !!(json.title && json.url);

    if (validBreakingNewsJson) {
      const pageStore = usePage.getState();
      const subType = getSubType(pageStore);
      const excludedPageType = EXCLUDED_SUBTYPES.includes(subType);
      if (excludedPageType) return;

      const lastShownBreakingNews = USE_LOCAL_STORAGE ? localStorage.getItem('breaking-news') : {};
      const jsonString = JSON.stringify(json);

      if (jsonString !== lastShownBreakingNews) {
        // To simplify we currently only set localstorage on an actual BN,
        // a failed request will resolve to no breaking news which could cause complications
        if (USE_LOCAL_STORAGE) localStorage.setItem('breaking-news', jsonString);
        state.showBreakingNews(json);
        return;
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    pollForBreakingNews({ endpoint, pollingPlan, state });
  };

const pollForBreakingNews = ({ endpoint, pollingPlan, state }: PollingFunctionOpts) => {
  const nextCheckMillis = pollingPlan.shift();

  if (nextCheckMillis !== undefined) {
    const timerRef = setTimeout(checkForBreakingNews({ endpoint, pollingPlan, state }), nextCheckMillis);
    state.setTimerRef(timerRef);
  }
};

const BreakingNews: React.FC<BreakingNewsProps> = ({ config: { endpoint } }) => {
  const {
    actions: { clickEvent },
    state: {
      client: { analytics },
    },
  } = useAnalyticStore((store) => store);
  const state = useBreakingNewsStore();

  useEffect(() => {
    const opts: PollingFunctionOpts = {
      endpoint,
      pollingPlan: POLLING_PLAN,
      state,
    };
    pollForBreakingNews(opts);
    return () => state.clearTimeout();
  }, []);

  useEffect(() => {
    if (state.url) {
      clickEvent('BREAKING_NEWS:ON_RENDER', {
        componentTitle: 'breakingnewsOnRender',
        BreakingNewsTitle: state.title,
        BreakingNewsURL: state.url,
        ...analytics,
      });
    }
  }, [state.url]);

  const handleAnalyticsOnClose = () => {
    clickEvent('BREAKING_NEWS:CLOSED', {
      componentTitle: 'breakingnewsClosed',
      BreakingNewsTitle: state.title,
      BreakingNewsURL: state.url,
      ...analytics,
    });
  };

  const handleAnalyticsOnClick = (e: any) => {
    e.preventDefault();
    clickEvent('BREAKING_NEWS:LINK_CLICK', {
      componentTitle: 'breakingnewsLinkClick',
      BreakingNewsTitle: state.title,
      BreakingNewsURL: state.url,
      ...analytics,
    });
    if (state.url) {
      window.location.href = state.url;
    } else {
      throw new Error(`Failed to redirect as URL not present`);
    }
  };

  return state.visible ? (
    <section id={styles['breaking-banner']}>
      <div className={styles['breaking-container']}>
        <a className={styles['breaking-info']} href={state.url} onClick={handleAnalyticsOnClick}>
          <Blinker color="red" />
          <h3 className={styles['breaking-prefix']}>BREAKING NEWS</h3>
          <h2 className={styles['breaking-headline']}>
            {state.title}
            <ArrowRight color="var(--color-com-breaking-banner-fg)" />
          </h2>
        </a>
        <button
          aria-label="Close"
          className={styles['breaking-close']}
          onClick={() => {
            handleAnalyticsOnClose();
            state.hideBreakingNews();
          }}
        >
          <Close color="var(--color-com-breaking-banner-fg, #111211)" />
        </button>
      </div>
    </section>
  ) : (
    <></>
  );
};

export default BreakingNews;
