import { NavBarProps } from '@/ts/interfaces';
import NavigationItem from '@/components/atoms/NavigationItem/NavItem';
import ArrowIcon from '@/components/atoms/Icons/ArrrowHead';
import { useAnalyticStore } from '@/store/analytics/analytics-provider';
import { useCallback, useEffect, useState } from 'react';
import MoreCurtain from '../NavCurtain/MoreCurtain';
import styles from './styles/Navbar.module.scss';

const Navbar: React.FC<NavBarProps> = ({
  primaryItems = [],
  curtainItems = {},
  breakpoint = '',
  publication,
  publicationName,
  socialPlatforms,
  isCurtainOpen,
  territorySwitcher,
  setIsCurtainOpen,
  secondaryItems = [],
  isExperimentOn,
}) => {
  const [isMounted, setIsMounted] = useState(false);
  const [analyticsThresholdMoreOpen, setAnalyticsThresholdMoreOpen] = useState(false);
  const [analyticsThresholdMoreClose, setAnalyticsThresholdMoreClose] = useState(false);
  const {
    actions: { clickEvent },
    state: {
      client: { analytics },
    },
  } = useAnalyticStore((store) => store);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const trackMoreOpen = useCallback(() => {
    if (!analyticsThresholdMoreOpen) {
      clickEvent('HEADER_NAV:MORE_OPEN', { componentTitle: 'More', linkURL: window.location.href, ...analytics });
      setAnalyticsThresholdMoreOpen(true);
    }
  }, [analyticsThresholdMoreOpen, clickEvent]);

  const trackMoreClose = useCallback(() => {
    if (!analyticsThresholdMoreClose) {
      clickEvent('HEADER_NAV:MORE_CLOSE', { componentTitle: 'Close', linkURL: window.location.href, ...analytics });
      setAnalyticsThresholdMoreClose(true);
    }
  }, [analyticsThresholdMoreClose, clickEvent]);

  const handleMouseEnterMore = () => {
    setIsCurtainOpen(true);
  };

  const handleMouseEnterNavItem = () => {
    setIsCurtainOpen(false);
  };

  const handleMouseLeaveCurtain = () => {
    setIsCurtainOpen(false);
    trackMoreClose();
  };

  const toggle = () => {
    setIsCurtainOpen((prevState) => !prevState);
    if (!isCurtainOpen) {
      trackMoreClose();
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      toggle();
    }
  };

  return isMounted ? (
    <>
      <nav data-testid="primary-header-nav" className={styles.navbar}>
        <ul>
          {primaryItems.slice(0, 4).map((item, index) => (
            <NavigationItem key={index} {...item} onMouseEnter={handleMouseEnterNavItem} />
          ))}
          <NavigationItem
            accessibility={{
              role: 'button',
              'aria-haspopup': !isCurtainOpen,
              'aria-expanded': isCurtainOpen,
              'aria-controls': 'MoreCurtain',
            }}
            key="MoreNavItem"
            onMouseEnter={handleMouseEnterMore}
            onClick={toggle}
            onKeyDown={handleKeyDown}
            icon={<ArrowIcon isUp={isCurtainOpen} color="currentColor" />}
            isActive={isCurtainOpen}
            title={'More'}
            linkBehaviour={''}
          />
        </ul>
      </nav>
      <MoreCurtain
        items={curtainItems}
        isOpen={isCurtainOpen}
        breakpoint={breakpoint}
        onMouseLeave={handleMouseLeaveCurtain}
        onClick={toggle}
        publication={publication}
        publicationName={publicationName}
        socialPlatforms={socialPlatforms}
        trackMoreOpen={trackMoreOpen}
        trackMoreClose={trackMoreClose}
        secondaryItems={secondaryItems}
        isExperimentOn={isExperimentOn}
        territorySwitcher={territorySwitcher}
      />
    </>
  ) : (
    <></>
  );
};

export default Navbar;
