import AnalyticsTrack from '@/components/atoms/AnalyticsTrack/AnalyticsTrack';
import linkHelper from '@/helpers/analytics/linkHelper';
import Image from 'next/image';
import styles from './styles/Masthead.module.scss';

export interface MastheadProps {
  publication: string;
  publicationName: string;
  variant?: MastheadVariant;
  mastheadLogo?: string;
  logoSize?: { width: number; height: number };
  withBurger: boolean;
}

export type MastheadVariant = 'default' | 'reverse' | 'square' | 'seasonal';

const Masthead: React.FC<MastheadProps> = ({
  publication,
  publicationName,
  variant,
  logoSize,
  mastheadLogo,
  withBurger,
}) => {
  const logoName = mastheadLogo || `masthead-${publication}-${variant || 'default'}.svg`;
  const logoPath = `/assets/${publication}/logos/${logoName}`;

  return (
    <AnalyticsTrack
      analyticsData={linkHelper('HEADER_NAV:LOGO', '/', {
        componentTitle: publicationName,
        componentID: '',
        componentText: '',
      })}
    >
      <Image
        className={`${styles.masthead} ${withBurger ? '' : styles['no-burger']}`}
        alt={publicationName}
        src={logoPath}
        loading="eager"
        {...logoSize}
      />
    </AnalyticsTrack>
  );
};

export default Masthead;
