'use client';

import { useEffect, useRef } from 'react';
import Script from 'next/script';
import useFeliz, { FelizStore } from '@/store/feliz/feliz';
import { isNavigatorOnly } from '@/helpers/utils/publication';
import FELIZ, { FelizConfigFromPublicationConfig } from '../../../constants/feliz';

const processFelizData = (recommendation: any) => {
  const felizData = recommendation;
  let processedFelixData: any;
  if (felizData) {
    processedFelixData = felizData.map((item: any) => {
      const { url } = item;
      const { pathname } = new URL(url);

      return {
        ...item,
        url: `${pathname}?int_source=nba`,
      };
    });
  }
  return processedFelixData;
};

const getFelizPageConfig = (publicationConfig: FelizConfigFromPublicationConfig, publication: string | undefined) => {
  const felixConfig = window.feliz.getPageConfig();
  const { rightHandRail: rhs } = publicationConfig;

  return {
    newsletter: felixConfig.newsletter,
    newsletterInline: felixConfig[FELIZ.CONFIG.NEWSLETTER_INLINE],
    webAlert: {
      notification: felixConfig[FELIZ.CONFIG.WEB_ALERT_NOTIFICATION],
      subjective: felixConfig[FELIZ.CONFIG.WEB_ALERT_SUBJECTIVE],
    },
    ncuProduct: felixConfig[FELIZ.CONFIG.NCU_PRODUCT],
    downloadApp: felixConfig[FELIZ.CONFIG.DOWNLOAD_APP],
    addToHomeScreen: felixConfig[FELIZ.CONFIG.ADD_TO_HOME_SCREEN],
    recirculation: {
      rhs: {
        mostRead:
          rhs.mostReadComponent.maximumNoOfTeasers > 0
            ? processFelizData(felixConfig.rhsRecommendations.slice(0, rhs.mostReadComponent.maximumNoOfTeasers))
            : [],
        recommended: processFelizData(
          felixConfig.rhsRecommendations.slice(
            rhs.mostReadComponent.maximumNoOfTeasers > 0 ? rhs.mostReadComponent.maximumNoOfTeasers : 0,
            rhs.recommendedComponent.maximumNoOfTeasers + rhs.mostReadComponent.maximumNoOfTeasers
          )
        ),
      },
      inline: {
        mostRead: processFelizData(felixConfig.rhsRecommendations.slice(0, FELIZ.MAX_NO.INLINE.MOST_READ_TEASERS)),
        knowledgeRecommender: processFelizData(
          felixConfig.inlineRecommendations.slice(0, FELIZ.MAX_NO.INLINE.KNOWLEDGE_RECOMMENDER)
        ),
        readNext: isNavigatorOnly(publication)
          ? processFelizData(felixConfig.inlineRecommendations.slice(0, FELIZ.MAX_NO.INLINE.READ_NEXT_NAV))
          : processFelizData(felixConfig.inlineRecommendations.slice(0, FELIZ.MAX_NO.INLINE.READ_NEXT)),
        readMostRecommender: processFelizData(
          felixConfig.recommendations.slice(0, FELIZ.MAX_NO.INLINE.READ_MORE_RECOMMENDED)
        ),
      },
    },
  };
};

const setFelizPageConfig = (
  store: FelizStore,
  config: FelizConfigFromPublicationConfig,
  publication: string | undefined
) => {
  const pageConfig: any = getFelizPageConfig(config, publication);
  store.actions.addFelizConfigToStore(pageConfig);
};

const initiateFeliz = (store: FelizStore, config: FelizConfigFromPublicationConfig, publication: string | undefined) =>
  window.feliz
    ? setFelizPageConfig(store, config, publication)
    : document.addEventListener(FELIZ.EVENTS.NAME_FELIZ, () => setFelizPageConfig(store, config, publication));

interface FelizPros {
  config: FelizConfigFromPublicationConfig;
  activeMVT: string;
  publication: string;
}

const Feliz: React.FC<FelizPros> = ({ config, activeMVT, publication }) => {
  const felizStore = useFeliz();
  const felizInitialized = useRef(false);

  useEffect(() => {
    if (!felizInitialized.current) {
      initiateFeliz(felizStore, config, publication);
      felizInitialized.current = true;
    }
  });

  useEffect(() => {
    if (activeMVT) {
      const pushEvent = () => {
        window.feliz.event(FELIZ.EVENTS.OPTIMISE_EXPERIMENT, {
          id: activeMVT,
        });
        window.removeEventListener(FELIZ.EVENTS.NAME_FELIZ, pushEvent);
      };
      if (window.feliz) {
        pushEvent();
      } else {
        window.addEventListener(FELIZ.EVENTS.NAME_FELIZ, pushEvent);
      }
    }
  }, []);

  return <Script key="feliz-script" src={FELIZ.URL.SCRIPT} />;
};

export default Feliz;
