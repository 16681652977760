interface NavArrowRightProps {
  color?: string;
  title?: string;
}

const NavArrowRight: React.FC<NavArrowRightProps> = ({
  color = `var(--color-sem-icon-primary-default)`,
  title = 'This is the read next arrow',
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label={title}
    data-testid="nav-arrow-right-icon"
  >
    <g clipPath="url(#clip0_3691_12179)">
      <path
        d="M12 19L19 12L12 5M19 12L5 12"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3691_12179">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default NavArrowRight;
