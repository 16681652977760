'use client';

import { useRef } from 'react';
import useCountryCode from '@/store/countryCode/countryCode';
import { UpperTwoLetter } from '@/commercial/types';

function CountryCodeInitializer({ countryCode }: { countryCode: UpperTwoLetter }) {
  const initialized = useRef(false);

  if (!initialized.current) {
    useCountryCode.getState().actions.addCountryCodeToStore(countryCode);
    initialized.current = true;
  }
  return null;
}

export default CountryCodeInitializer;
