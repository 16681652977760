'use client';

import { isVendorConsented } from '@/store/consent/selectors';
import useConsentData from '@/store/consent/consent';
import ScriptLoader from '../ScriptLoader/ScriptLoader';

const FACEBOOK_PIXEL_SCRIPT = `
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window,document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '331470507705028');
fbq('track', 'PageView');
fbq('track', 'enterNow');`;

const FACEBOOK_NO_SCRIPT_TAG = `https://www.facebook.com/tr?id=331470507705028&ev=PageView&noscript=1`;

const FacebookPixel: React.FC = () => {
  const consentStore = useConsentData();
  const consented = isVendorConsented('facebook')(consentStore);

  if (consented) {
    const noscript = document.createElement('noscript');
    const img = document.createElement('img');
    img.height = 1;
    img.width = 1;
    img.src = FACEBOOK_NO_SCRIPT_TAG;

    noscript.appendChild(img);
    document.body.appendChild(noscript);

    return (
      <ScriptLoader testId="facebook-pixel-script" id="facebook-pixel">
        {FACEBOOK_PIXEL_SCRIPT}
      </ScriptLoader>
    );
  }
  return null;
};
export default FacebookPixel;
