'use client';

import { useRef } from 'react';
import useJWClickToPlayGeosConfig from '@/store/jwClickToPlayGeosConfig/jwClickToPlayGeosConfig';
import { JWClickToPlayGeos } from '@/commercial/types';

function JWClickToPlayGeosConfigInitializer({ jwClickToPlayGeos }: { jwClickToPlayGeos: JWClickToPlayGeos }) {
  const initialized = useRef(false);

  if (!initialized.current) {
    useJWClickToPlayGeosConfig.getState().actions.addClickToPlayGeoConfig(jwClickToPlayGeos);
    initialized.current = true;
  }
  return null;
}

export default JWClickToPlayGeosConfigInitializer;
