'use client';

import { useAnalyticStore } from '@/store/analytics/analytics-provider';
import { useEffect, useState } from 'react';

export interface WebAlertsServiceProps extends React.PropsWithChildren {
  webAlertsConfig:
    | {
        defaultIcon: string;
        defaultTitle: string;
        defaultActionURL: string;
        appKey: string;
        token: string;
        vapidPublicKey: string;
      }
    | null
    | undefined;
  oneSignalEnabled: boolean;
  oneSignalAppId: string;
}

const WebAlertsService: React.FC<WebAlertsServiceProps> = ({ webAlertsConfig, oneSignalEnabled, oneSignalAppId }) => {
  const [oneSignalInit, setOneSignalInit] = useState(false);
  const {
    actions: { clickEvent },
    state: {
      client: { analytics },
    },
  } = useAnalyticStore((store) => store);

  useEffect(() => {
    if (!webAlertsConfig) {
      return () => {};
    }

    const injectAirshipSDK = () => {
      const scriptContent = `
        !function(n,r,e,t,c){var i,o="Promise"in n,u={then:function(){return u},catch:function(n){
          return n(new Error("Airship SDK Error: Unsupported browser")),u}},s=o?new Promise((function(n,r){i=function(e,t){e?r(e):n(t)}})):u
          ;s._async_setup=function(n){if(o)try{i(null,n(c))}catch(n){i(n)}},n[t]=s;var a=r.createElement("script");a.src=e,a.async=!0,a.id="_uasdk",
          a.rel=t,r.head.appendChild(a)}(window,document,'https://aswpsdkus.com/notify/v1/ua-sdk.min.js',
            'UA', {
              vapidPublicKey: '${webAlertsConfig.vapidPublicKey}',
              websitePushId: 'web.com.reach.all',
              appKey: '${webAlertsConfig.appKey}',
              token: '${webAlertsConfig.token}'
            });
      `;
      const scriptElement = document.createElement('script');
      scriptElement.text = scriptContent;
      document.body.appendChild(scriptElement);
      const airshipEvent = new Event('web.alerts.sdk.snippet.loaded');
      window.dispatchEvent(airshipEvent);
      return scriptElement;
    };

    const injectOneSignalSDK = () => {
      const scriptSrc = 'https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js';
      const scriptContent = `
            window.OneSignalDeferred = window.OneSignalDeferred || [];
            OneSignalDeferred.push(async function(OneSignal) {
              OneSignal.setConsentRequired(true);
              await OneSignal.init({
                appId: '${oneSignalAppId}',
              });
              const webSDKEvent = new Event('web.alerts.sdk.snippet.loaded');
              window.dispatchEvent(webSDKEvent);
            });
            `;
      const scriptElementOne = document.createElement('script');
      scriptElementOne.src = scriptSrc;
      scriptElementOne.defer = true;
      const scriptElementTwo = document.createElement('script');
      scriptElementTwo.text = scriptContent;
      document.head.appendChild(scriptElementOne);
      document.head.appendChild(scriptElementTwo);
      return [scriptElementOne, scriptElementTwo];
    };

    const registerServiceWorker = () => {
      window.addEventListener('load', () => {
        if ('serviceWorker' in navigator) {
          let swUrl;
          if (oneSignalEnabled) {
            swUrl = '/push/onesignal/OneSignalSDKWorker.js';
          } else {
            const queryParams = new URLSearchParams({
              a: encodeURIComponent(webAlertsConfig.appKey),
              t: encodeURIComponent(webAlertsConfig.token),
              vap: encodeURIComponent(webAlertsConfig.vapidPublicKey),
              icon: encodeURIComponent(webAlertsConfig.defaultIcon),
              title: encodeURIComponent(webAlertsConfig.defaultTitle),
              url: encodeURIComponent(webAlertsConfig.defaultActionURL),
            }).toString();
            swUrl = `/push-worker.js?${queryParams}`;
          }
          navigator.serviceWorker
            .register(swUrl)
            .then((registration: any) => {
              console.log('Service worker registered with scope :', registration.scope);
            })
            .catch((error) => {
              console.warn('Error registering service worker:');
              console.warn(error);
            });
        }
      });
    };
    const oneSignalScriptElements = oneSignalEnabled ? injectOneSignalSDK() : [];
    const airshipScriptElement = injectAirshipSDK();

    registerServiceWorker();

    return () => {
      if (airshipScriptElement) {
        document.body.removeChild(airshipScriptElement);
      }
      if (oneSignalEnabled && oneSignalScriptElements.length) {
        oneSignalScriptElements.forEach((element) => document.head.removeChild(element));
      }
    };
  }, [webAlertsConfig, oneSignalEnabled]);

  useEffect(() => {
    const url = window.location.href;
    const queryParams = new URLSearchParams(window.location.search);
    const intSource = queryParams.get('int_source');
    if (intSource && intSource === 'push') {
      clickEvent('WEB_ALERTS:WEB_ALERT_NOTIFICATION_CLICKED', { linkURL: url, ...analytics });
    }
  }, []);

  useEffect(() => {
    if (oneSignalEnabled && oneSignalInit) {
      const clickEventListener = () => {
        const url = window.location.href;
        clickEvent('WEB_ALERTS:WEB_ALERT_NOTIFICATION_CLICKED', { linkURL: url, ...analytics });
      };

      const addNotificationListener = async () => {
        const oneSignalSDK = await window.OneSignal;
        if (oneSignalSDK) {
          oneSignalSDK.Notifications.addEventListener('click', clickEventListener);
        }
      };

      addNotificationListener();
    }
  }, [oneSignalInit]);

  useEffect(() => {
    if (!oneSignalInit && oneSignalEnabled) {
      window.addEventListener('web.alerts.sdk.snippet.loaded', () => {
        setOneSignalInit(true);
      });
    }
  }, [oneSignalInit]);

  return null;
};

export default WebAlertsService;
