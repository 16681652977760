'use client';

import Masthead from '@/components/molecules/Masthead/Masthead';
import { HeaderProps, UserBasicInfo, UserOnly } from '@/ts';
import SocialFollowBar from '@/components/molecules/SocialFollowBar/SocialFollowBar';
import useBurger from '@/hooks/useBurger';
import useAvatar from '@/hooks/useAvatar/useAvatar';
import NavCurtain from '@/components/molecules/NavCurtain/NavCurtain';
import Navbar from '@/components/molecules/NavBar/NavBar';
import clientWidth from '@/helpers/utils/clientWidth';
import { useCallback, useEffect, useState } from 'react';
import SecondaryNavigationHeader from '@/components/molecules/SecondaryNavigation/SecondaryNavigation';
import MastheadLink from '@/components/atoms/TextLink/TextLink';
import ErrorBoundaryFallback from '@/components/molecules/ErrorBoundaryFallback/ErrorBoundaryFallback';
import useGptSlots from '@/store/gptSlots/gptSlots';
import { hasSkyscraperAdResponse } from '@/store/gptSlots/selectors';
import { hasSkinAd } from '@/store/customAd/selectors';
import useCustomAdFormats from '@/store/customAd/customAdFormats';
import avatarStyles from '@/components/atoms/Avatar/styles/Avatar.module.scss';
import Avatar from '@/components/atoms/Avatar/Avatar';
import AvatarDropdown from '@/components/molecules/AvatarDropdown/AvatarDropdown';
import CommercialBox from '@/components/molecules/CommercialBox/CommercialBox';
import useAdsConfig from '@/store/adsConfig/adsConfig';
import { getGPTConfigById } from '@/store/adsConfig/selectors';
import { GPTConfigProps } from '@/commercial/types';
import { isNavigatorOnly } from '@/helpers/utils/publication';
import runExperiment from '@/helpers/growthbook/runExperiment';
import styles from './styles/Header.module.scss';
import AuthModal from '../AuthModal/AuthModal';

const Header: React.FC<HeaderProps> = ({
  $backgroundColor = 'primary',
  $publicationName,
  nav,
  isSectionPage,
  publication,
  socialFollowBar: { socialPlatforms, ctaVariant },
  dataTestId = 'header',
  logo,
  mastheadLogo,
  disableCommercial,
  loginRadiusEnv,
  features = {},
}) => {
  const [isMounted, setIsMounted] = useState(false);
  const breakpoint = clientWidth();
  const [isDesktop, setIsDesktop] = useState(false);
  const {
    avatarEnabled,
    burgerEnabled,
    isAuthFlowEnabled = false,
    navigationEnabled = true,
    socialFollowBarEnabled,
    secondaryNavigationHeaderEnabled,
    territorySwitcherEnabled,
    extendedHeaderEnabled = true,
    growthbookEnabled,
  } = features;
  const { Burger, isOpen: isMobileNavOpen } = useBurger(navigationEnabled);
  const {
    avatarClickHandler,
    isLoggedIn,
    user,
    isMenuOpened,
  }: {
    avatarClickHandler: (origin?: string) => void;
    isLoggedIn: boolean;
    user: UserOnly;
    isMenuOpened: boolean;
  } = useAvatar(isAuthFlowEnabled);
  const [isCurtainOpen, setIsCurtainOpen] = useState(false);
  const customAdStore = useCustomAdFormats();
  const gptSlotsStore = useGptSlots();
  const adConfigStore = useAdsConfig();
  const NAVIGATOR_ONLY = isNavigatorOnly(publication);

  const getUserBasicInfo = useCallback(
    (userOnly: UserOnly): UserBasicInfo => {
      const { FirstName, LastName, Email } = userOnly;
      return {
        firstName: FirstName || '',
        lastName: LastName || '',
        email: Email[0].Value || '',
      } as UserBasicInfo;
    },
    [user]
  );

  useEffect(() => {
    setIsMounted(true);
    setIsDesktop(['large', 'xlarge', 'xxlarge'].includes(breakpoint));
  }, [breakpoint]);

  // adds a h1 tag around the masthead for SEO on homepages
  const Wrapper: React.FC<{ children: React.ReactNode }> = ({ children }) =>
    isSectionPage ? <h1 className={styles['seo-heading']}>{children}</h1> : <>{children}</>;

  const headerClassNames = `${styles.header}
  ${styles[`header-${$backgroundColor}`]}
  ${navigationEnabled && styles['with-burger']}
  ${NAVIGATOR_ONLY && styles['navigator-only-header']}
  ${
    !extendedHeaderEnabled || hasSkinAd(customAdStore) || hasSkyscraperAdResponse(gptSlotsStore)
      ? ''
      : styles['extended-header']
  }
  .tab-focus-all-elems
  `;

  const slotConfig: GPTConfigProps | null = getGPTConfigById('div-gpt-ad-ad-web-strip')(adConfigStore);
  const hasMastheadWebStripEnabled = slotConfig?.enabled === true;

  const isExperimentOn = growthbookEnabled ? runExperiment({ feature: 'id-9296' }) === '1' : false;

  return (
    <ErrorBoundaryFallback>
      <header data-testid={dataTestId} className={headerClassNames}>
        {!isDesktop && burgerEnabled && <Burger />}
        <Wrapper>
          <MastheadLink
            href="/"
            title={`Go to the ${$publicationName} homepage`}
            ariaLabel={`Go to the ${$publicationName} homepage`}
            dataLinkTracking={`Header|MainLogo|Image|${$publicationName}`}
            dataTmDataTrack="nav-home"
            tabIndex={isMobileNavOpen ? -1 : 0}
            focusInternal
          >
            {isMobileNavOpen && !isDesktop ? null : (
              <Masthead
                publicationName={$publicationName}
                publication={publication}
                logoSize={logo}
                withBurger={!NAVIGATOR_ONLY}
                mastheadLogo={mastheadLogo}
              />
            )}
          </MastheadLink>
        </Wrapper>
        {!NAVIGATOR_ONLY && navigationEnabled && isDesktop && (
          <Navbar
            primaryItems={nav?.['Header-Primary-4']}
            curtainItems={nav?.['Header-Primary-Curtain']}
            breakpoint={breakpoint}
            publication={publication}
            publicationName={$publicationName}
            socialPlatforms={socialPlatforms}
            isCurtainOpen={isCurtainOpen}
            setIsCurtainOpen={setIsCurtainOpen}
            secondaryItems={nav?.['Header-Secondary']}
            isExperimentOn={isExperimentOn}
            territorySwitcher={{
              territorySwitcherEnabled,
              territories: nav?.Territories,
            }}
          />
        )}
        {isMounted && (!navigationEnabled || breakpoint === 'xlarge' || isMobileNavOpen) && socialFollowBarEnabled && (
          <SocialFollowBar
            socialButtons={{
              socialPlatforms,
              designType: ctaVariant,
              hideLabel: true,
            }}
            hideHeaderText
            dataTmDataTrack="nav"
            isPrimaryHeader={true}
            publication={publication}
            publicationName={$publicationName}
            dataTestId="social-follow-bar"
            location="header"
          />
        )}
        {avatarEnabled ? (
          <button
            className={avatarStyles['avatar-container']}
            data-testid="avatar"
            onClick={() => avatarClickHandler()}
            aria-label={`User Avatar - ${
              isLoggedIn ? 'redirects to user profile' : 'redirects to sign in and sign up'
            }`}
          >
            <Avatar user={isLoggedIn && user ? getUserBasicInfo(user) : undefined} />
            {isAuthFlowEnabled && (
              <AuthModal
                loginRadiusEnv={loginRadiusEnv}
                publicationId={publication}
                publicationName={$publicationName}
              />
            )}
            {isAuthFlowEnabled && isLoggedIn && isMenuOpened && <AvatarDropdown />}
          </button>
        ) : (
          <></>
        )}
        <div className={styles['secondary-nav-container']}>
          {navigationEnabled && !isMobileNavOpen && secondaryNavigationHeaderEnabled && !isExperimentOn && (
            <SecondaryNavigationHeader items={nav?.['Header-Secondary']} isCurtainOpen={isCurtainOpen} />
          )}
          {navigationEnabled && (
            <NavCurtain
              isOpen={isMobileNavOpen}
              navData={nav}
              secondaryNavigationHeaderEnabled={secondaryNavigationHeaderEnabled}
              territorySwitcher={{
                territorySwitcherEnabled,
                territories: nav?.Territories,
              }}
            />
          )}
        </div>
        {disableCommercial !== true && hasMastheadWebStripEnabled && (
          <div className={`${styles['mastheadwebstrip-container']} ${isExperimentOn ? styles['is-experiment'] : ''}`}>
            <CommercialBox position={2} location="header" />
          </div>
        )}
      </header>
    </ErrorBoundaryFallback>
  );
};

export default Header;
