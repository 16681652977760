'use client';

import React, { ReactNode } from 'react';
import Feliz from '@/components/molecules/Feliz/Feliz';
import MantisIdService from '@/components/atoms/MantisIdService/MantisIdService';
import AutoAcceptCMPService from '@/components/atoms/AutoAcceptCMPService/AutoAcceptCMPService';
import { useParams } from 'next/navigation';
import AdsConfigStoreInitializer from '@/components/services/AdsConfigStoreInitializer';
import getAdsConfig from '@/helpers/commercial/adsConfig';
import checkProduction from '@/helpers/utils/checkProduction';
import getTakeoverDetectionData from '@/helpers/commercial/takeoverDetection';
import getLightningLoadData from '@/helpers/commercial/lightningLoadData';
import TakeoverDetectionInitializer from '@/components/services/TakeoverDetectionInitializer';
import usePage from '@/store/page/page';
import getHydraData from '@/helpers/commercial/getHydraData';
import getCommercialBoxesConfig from '@/helpers/commercial/getCommercialBoxesConfig';
import getBoxAggregationData from '@/helpers/commercial/getBoxAggregationData';
import { WithLoginContext } from '@/contexts/login/loginProvider';
import CustomAdService from '@/components/atoms/CustomAdService/CustomAdService';
import { isNavigatorOnly } from '@/helpers/utils/publication';
import ErrorBoundaryFallback from '@/components/molecules/ErrorBoundaryFallback/ErrorBoundaryFallback';
import BreakingNews from '@/components/molecules/BreakingNews/BreakingNews';
import { BreakingNewsPropsConfig } from '@/ts/interfaces/breakingNews';
import Header from '../organisms/Header/Header';
import Footer from '../organisms/Footer/Footer';
import FloatingFooter from '../organisms/FloatingFooter/FloatingFooter';
import styles from './styles/MainWrapper.module.scss';
import { FOOTER } from '../../constants';
import Faro from '../atoms/Faro/Faro';
import ConsentDataService from '../atoms/ConsentDataService/ConsentDataService';
import LotameService from '../atoms/LotameService/LotameService';
import AdnamiService from '../atoms/AdnamiService/AdnamiService';
import GDPRPrivacyLink from '../molecules/GPDRPrivacyLink/GDPRPrivacyLink';
import CommercialBox from '../molecules/CommercialBox/CommercialBox';
import LoggerCookieService from '../atoms/LggerCookieService/LoggerCookieService';
import TikTokPixel from '../atoms/MarketingPixels/TikTokPixel';
import TwitterPixel from '../atoms/MarketingPixels/TwitterPixel';
import FacebookPixel from '../atoms/MarketingPixels/FacebookPixel';
import Gutter from '../molecules/Gutter/Gutter';
import VideoStoreInitializer from '../services/VideoStoreInitializer';
import SkipToMainContent from '../molecules/SkipToMainContent/SkipToMainContent';
import PageCategoryStoreInitializer from '../services/PageCategoryStoreInitializer';
import LightningLoadInitializer from '../services/LightningLoadDetectionInitializer';
import GPTFeatureControlInitializer from '../services/GPTFeatureControlInitializer';

import ChartbeatTracker from '../atoms/ChartbeatTracker/ChartbeatTracker';
import { CHARTBEAT_SRC, CHARTBEAT_VIDEO_SRC } from '../atoms/ChartbeatTracker/constants';
import Sovrn from '../atoms/Sovrn/Sovrn';
import HydraStoreInitializer from '../services/HydraStoreInitializer';
import GrowthBookWrapper from '../services/providers/GrowthbookService/GrowthBookWrapper';
import CommercialBoxesConfigStoreInitializer from '../services/CommercialBoxesConfigStoreInitializer';
import CommercialBoxAggStoreInitializer from '../services/CommercialBoxAggStoreInitializer';
import CIPAConsentNotice from '../molecules/CIPAConsentNotice/CIPAConsentNotice';
import CommercialFeatureControlStoreInitializer from '../services/CommercialFeatureControlStoreInitializer';
import CommercialPageData from '../atoms/CommercialPageData/CommercialPageData';
import AffiliateStickyContentBar from '../atoms/AffiliateContent/AffiliateStickyContentBar';
import CountryCodeInitializer from '../services/CountryCodeInitializer';
import JWClickToPlayGeosConfigInitializer from '../services/JWClickToPlayGeosConfigInitializer';

interface LayoutProps {
  children: ReactNode;
  configData: any;
}

const kitemarkData = FOOTER.KITEMARK_DATA;

const Layout: React.FC<LayoutProps> = ({ children, configData = { publication: '', publicationName: '' } }) => {
  const {
    avatarEnabled,
    adnamiEnabled,
    breakingNewsEnabled,
    breakingNewsEndpoint,
    commercialConfig,
    componentVariants,
    feliz,
    publication,
    publicationName,
    socialPlatforms,
    seoPublication,
    chartbeatUid,
    customGDPRButtonEnabled,
    gdprPrivacyLinkEnabled,
    nav,
    navigationEnabled = true,
    burgerEnabled,
    extendedHeaderEnabled,
    isAuthFlowEnabled = false,
    isolateCommercialFeature,
    disableCommercial,
    shareUrl,
    felizEnabled,
    footerEnabled,
    floatingFooterEnabled,
    headerEnabled,
    socialFollowBarEnabled,
    secondaryNavigationHeaderEnabled,
    loginRadiusEnv,
    lotameServiceEnabled,
    mantisIdServiceEnabled,
    mastheadLogo,
    floatAndPark,
    isVideoOnPage,
    isSportArticle,
    hasActiveLegalProceedings,
    isSensitiveArticle,
    domainName,
    sovrnRefreshEnabled,
    growthbookEnabled,
    growthbookConfig,
    gptLazyLoadingEnabled,
    affiliateStickyBarEnabled,
    countryCode,
    publicFolder,
    territorySwitcherEnabled,
    activeMVT,
    converseEnabled,
    jwClickToPlayGeos,
  } = configData;
  const pageData = usePage();
  const params = useParams();
  const adsConfigData = getAdsConfig(commercialConfig, params);
  const takeoverDetectionData = getTakeoverDetectionData(commercialConfig);
  const lightningLoadData = getLightningLoadData(commercialConfig);
  const hydra = getHydraData(commercialConfig);
  const commercialBoxesConfig = getCommercialBoxesConfig(commercialConfig);
  const breakingNewsConfig: BreakingNewsPropsConfig = { endpoint: breakingNewsEndpoint };
  const boxAggregationData = getBoxAggregationData(commercialConfig);
  const videoData = {
    isVideoOnPage,
    floatAndPark,
  };
  const pageCategoryData = {
    isSportArticle,
    hasActiveLegalProceedings,
    isSensitiveArticle,
    converseEnabled,
  };
  const commercialFeatureControlData = {
    isolateCommercialFeature,
    disableCommercial,
  };
  const gptFeatureControlData = {
    gptLazyLoadingEnabled,
  };
  // const chartbeatDomain = configData.domain.replace(/http(s)?(:)?(\/\/)?|(\/\/)?(www\.)?/g, '');
  // const chartbeatDomain = configData?.domain?.replace(/http(s)?(:)?(\/\/)?|(\/\/)?(www\.)?/g, '');

  const isSectionPage = !params?.article;
  const isProduction = checkProduction();
  const navItems =
    nav && (nav['Header-Primary-4'] || nav['Header-Primary-Curtain'])
      ? {
          'Header-Primary-4': nav['Header-Primary-4'],
          'Header-Primary-Curtain': nav['Header-Primary-Curtain'],
          'Header-Secondary': nav['Header-Secondary'],
          Territories: nav.Territories,
        }
      : {};

  const primaryFooter = nav && nav['Footer-Primary'] ? nav['Footer-Primary'] : { relation: [] };
  const secondaryFooter = nav && nav['Footer-Secondary'] ? nav['Footer-Secondary'] : { relation: [] };
  const copyRightPublication = nav && nav['Footer-Copyright'] ? nav['Footer-Copyright'] : '';

  const authors = pageData?.state?.server?.authors;
  const authorNames = authors?.map((author) => author.name).join(', ');
  const primarySection = pageData?.state?.server?.sections?.pageSection;
  const secondarySection = pageData?.state?.server?.sections?.pagesecondarySection;

  // Formatting of non-one-word section names eg. Whats'on
  const formatSection = (sectionPrimary: string, sectionSecondary: string) => {
    const formattedPrimarySection = sectionPrimary.toLowerCase().replace(/'/g, '').replace(/\s+/g, '-');

    const formattedSecondarySection = sectionSecondary.toLowerCase().replace(/'/g, '').replace(/\s+/g, '-');

    return secondarySection ? `${formattedPrimarySection},${formattedSecondarySection}` : `${formattedPrimarySection}`;
  };

  return (
    <GrowthBookWrapper
      growthbookEnabled={growthbookEnabled}
      growthbookConfig={growthbookConfig}
      publication={publication}
    >
      <WithLoginContext>
        {/* <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head> */}
        {pageData?.state?.server?.sections && (
          <ChartbeatTracker
            domain={domainName}
            chartbeatUid={chartbeatUid}
            sections={`${formatSection(primarySection, secondarySection)}`}
            authors={authorNames}
            title={''}
            path={''}
            chartbeatSrc={isVideoOnPage ? CHARTBEAT_VIDEO_SRC : CHARTBEAT_SRC}
          />
        )}
        <LoggerCookieService />
        <ConsentDataService />
        <AdsConfigStoreInitializer adsConfigData={adsConfigData} />
        <CommercialBoxesConfigStoreInitializer commercialBoxesConfig={commercialBoxesConfig} />
        <CommercialBoxAggStoreInitializer boxAggregationData={boxAggregationData} />
        <HydraStoreInitializer hydraData={hydra} />
        <VideoStoreInitializer videoData={videoData} />
        <PageCategoryStoreInitializer pageCategoryData={pageCategoryData} />
        <TakeoverDetectionInitializer takeoverDetectionData={takeoverDetectionData} />
        <LightningLoadInitializer lightningLoadData={lightningLoadData} />
        <CountryCodeInitializer countryCode={countryCode} />
        <JWClickToPlayGeosConfigInitializer jwClickToPlayGeos={jwClickToPlayGeos} />
        <GPTFeatureControlInitializer gptFeatureControlData={gptFeatureControlData} />
        {!isProduction && (isolateCommercialFeature || disableCommercial) && (
          <CommercialFeatureControlStoreInitializer featureControlData={commercialFeatureControlData} />
        )}
        <Gutter position={1} />
        <Gutter position={2} />
        {disableCommercial !== true && <CommercialBox location="header" position={1} />}
        <SkipToMainContent shareUrl={shareUrl}></SkipToMainContent>
        {headerEnabled && (
          <Header
            $backgroundColor="primary"
            $publicationName={publicationName}
            publication={publication}
            socialFollowBar={{
              socialPlatforms,
              ctaVariant: componentVariants.header.socialFollowCTA,
            }}
            logo={seoPublication && seoPublication.logo}
            features={{
              isAuthFlowEnabled,
              navigationEnabled,
              burgerEnabled,
              socialFollowBarEnabled,
              secondaryNavigationHeaderEnabled,
              avatarEnabled,
              territorySwitcherEnabled,
              growthbookEnabled,
              extendedHeaderEnabled,
            }}
            isSectionPage={isSectionPage}
            nav={navItems}
            loginRadiusEnv={loginRadiusEnv}
            mastheadLogo={mastheadLogo}
            disableCommercial={disableCommercial}
          />
        )}
        <main className={styles.main} id="main-content">
          {children}
        </main>
        {footerEnabled && (
          <Footer
            listLabel={FOOTER.MENUS_LABEL}
            sectionLinks={FOOTER.SECTION_LABEL}
            year={new Date().getFullYear()}
            primaryLinksData={!isNavigatorOnly(publication) && primaryFooter}
            kitemarkData={kitemarkData}
            secondaryLinksData={secondaryFooter}
            socialPlatforms={socialPlatforms}
            publication={publication}
            publicationName={publicationName}
            socialFollowBarEnabled={socialFollowBarEnabled}
            gdprPrivacyLinkEnabled={gdprPrivacyLinkEnabled}
            copyRightPublication={copyRightPublication}
          />
        )}
        {floatingFooterEnabled && (
          <FloatingFooter>
            {breakingNewsEnabled && breakingNewsEndpoint && (
              <ErrorBoundaryFallback>
                <BreakingNews config={breakingNewsConfig}></BreakingNews>
              </ErrorBoundaryFallback>
            )}
            {affiliateStickyBarEnabled && <AffiliateStickyContentBar />}
            {customGDPRButtonEnabled && <GDPRPrivacyLink />}
            {disableCommercial !== true && <CommercialBox location="sticky-bottom" position={1} />}
          </FloatingFooter>
        )}
        <CIPAConsentNotice linkData={secondaryFooter} publicFolder={publicFolder} />
        {felizEnabled && <Feliz config={feliz} activeMVT={activeMVT} publication={publication} />}
        {sovrnRefreshEnabled && <Sovrn />}
        {mantisIdServiceEnabled && <MantisIdService />}
        <AutoAcceptCMPService />
        {lotameServiceEnabled && <LotameService />}
        {adnamiEnabled && <AdnamiService />}
        <CustomAdService />
        <TikTokPixel />
        <TwitterPixel />
        <FacebookPixel />
        <CommercialPageData />
      </WithLoginContext>
    </GrowthBookWrapper>
  );
};

export const EBFaroLayout: React.FC<LayoutProps> = ({ children, configData }) => (
  <Faro environment={configData.environment} publication={configData.publication} faroEnabled={configData.faroEnabled}>
    <Layout configData={configData}>{children}</Layout>
  </Faro>
);

export default Layout;
