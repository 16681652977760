export const POLLING_PLAN = [
  2000, // immediately
  30000, // at 30 secs
  30000, // at 1 min
  60000, // at 2 mins
  60000, // at 3 mins
  60000, // at 4 mins
  60000, // at 5 mins
  300000, // at 10 mins
];

export const EXCLUDED_SUBTYPES = ['affiliate'];

export const LOG_PREFIX = '[Breaking News]';

export const USE_LOCAL_STORAGE = true; // this is to facilitate local testing
